import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const UserDash = React.lazy(() => import('./Users/UserDash'));
const CarModel = React.lazy(() => import('./CarsDropDown/CarModel'));
const CarDropDown = React.lazy(() => import('./CarsDropDown/CarDropDown'));
const WiningPost = React.lazy(() => import('./Wining Post'));
const ReportPost = React.lazy(() => import('./postReport'));
const PostDetails = React.lazy(() => import('./viewPostDetails'));

export const dashBoardConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/UserDash',
    element: <UserDash />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/winingPost',
    element: <WiningPost />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/carDropDown',
    element: <CarDropDown />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/carModel',
    element: <CarModel />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/reportPost',
    element: <ReportPost />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboards/reportPostDetails',
    element: <PostDetails />,
  },
];
