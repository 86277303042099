import React, {useState, useEffect} from 'react';
import {Box} from '@mui/material';
import {AppLoader} from '@crema';
import {baseUrl} from 'config/utils';
import axios from 'axios';
// import AppInfoView from '@crema/core/AppInfoView';
import {FETCH_ERROR, SHOW_MESSAGE} from 'shared/constants/ActionTypes';
import {useDispatch} from 'react-redux';
const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const [userData, setUserData] = useState({});
  const dispatch = useDispatch();
  const [updateData, setUpdateData] = useState({
    oldPass: '',
    newPass: '',
    reTypeNewPass: '',
  });
  useEffect(() => {
    getUserData();
  }, []);
  const getUserData = async () => {
    setIsLoading(true);
    const JWT = localStorage.getItem('JWTShowOff');
    try {
      let data = await axios.get(`${baseUrl}admin/getMe`, {
        headers: {
          Authorization: JWT,
        },
      });

      if (data?.status == 200) {
        // setUserData(data?.data?.user);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, 'api payload');
      setIsLoading(false);
    }
  };
  const uploadToApi = async () => {
    setIsLoading(isLoading);
    setUpdateData(updateData);
    if (
      updateData.oldPass == '' ||
      updateData.newPass == '' ||
      updateData.reTypeNewPass == ''
    ) {
      dispatch({
        type: FETCH_ERROR,
        payload: 'Please Fill All Fields',
      });
    } else {
      if (updateData.newPass == updateData.reTypeNewPass) {
        const JWT = localStorage.getItem('JWTShowOff');
        const payload = {
          oldPassword: updateData.oldPass,
          password: updateData.newPass,
          confirmPassword: updateData.reTypeNewPass,
        };

        try {
          let data = await axios.patch(
            `${baseUrl}admin/changePassword`,
            payload,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: JWT,
              },
            },
          );
          console.log('ChangePasswordApiData', data);
          if (data?.status == 200) {
            getUserData();
            setUpdateData({
              oldPass: '',
              newPass: '',
              reTypeNewPass: '',
            });
            dispatch({
              type: SHOW_MESSAGE,
              payload: ` Password Changed Successfully.`,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: 'Incorrect Old Password.',
            });
          }
        } catch (error) {
          setIsLoading(false);

          dispatch({
            type: FETCH_ERROR,
            payload: error.response.data.msg || 'Somethin went wrong try again',
          });
        }
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: 'Passwords Not Matched.',
        });
      }
    }
  };
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          maxWidth: '100%',
          height: '45vh',
        }}
      >
        {isLoading == false ? (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <div
              style={{
                height: '80%',
                width: '80%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
              }}
            >
              <span
                style={{
                  height: '60%',
                  width: '45%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <h4>Old Password</h4>
                <input
                  style={{
                    height: '40%',
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #DDDDDD',
                    borderRadius: '6px',
                  }}
                  placeholder='Please Enter Old Password'
                  type='password'
                  onChange={(e) => {
                    setUpdateData({...updateData, oldPass: e.target.value});
                  }}
                />
              </span>
              <span
                style={{
                  height: '60%',
                  width: '45%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <h4>New Password</h4>
                <input
                  style={{
                    height: '40%',
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #DDDDDD',
                    borderRadius: '6px',
                  }}
                  // value={drugTitle}
                  type='password'
                  placeholder='Please Enter New Password'
                  onChange={(e) => {
                    setUpdateData({...updateData, newPass: e.target.value});
                  }}
                />
              </span>
              <span
                style={{
                  height: '60%',
                  width: '45%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <h4>Re-Type New Password</h4>
                <input
                  style={{
                    height: '40%',
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #DDDDDD',
                    borderRadius: '6px',
                  }}
                  type='password'
                  placeholder='Please Re-Type New Password'
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      reTypeNewPass: e.target.value,
                    });
                  }}
                />
              </span>
            </div>
            <div
              style={{
                height: '20%',
                width: '95%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',

                alignItems: 'center',
              }}
            >
              <button
                style={{
                  height: '40px',
                  width: '28%',
                  backgroundColor: '#FF2800',
                  color: '#FFF',
                  borderRadius: '5px',
                  fontWeight: 'bold',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={uploadToApi}
              >
                Save Changes
              </button>
            </div>
          </div>
        ) : (
          <AppLoader />
        )}
        {/* <AppInfoView /> */}
      </Box>
    </>
  );
};

export default ChangePassword;
