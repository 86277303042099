import {FaUserAlt} from 'react-icons/fa';
import {
  userPlaceHolder,
  CarIcon,
  CarMakeIcon,
  WinningPostIcon,
  reportPostIcon,
} from 'assets/images';

const routesConfig = [
  {
    id: 'app',
    title: 'Application',
    messageId: 'sidebar.application',
    type: 'group',
    children: [
      {
        id: 'userDash',
        title: 'WinningPosts',

        messageId: 'Wining Posts',
        type: 'item',
        icon: <img src={WinningPostIcon} />,
        url: '/dashboards/winingPost',
      },
      {
        id: 'userDash',
        title: 'Report Post',

        messageId: 'Report Posts',
        type: 'item',
        icon: <img src={reportPostIcon} />,
        url: '/dashboards/reportPost',
      },
      {
        id: 'userDash',
        title: 'Users',

        messageId: 'User Dashboard',
        type: 'item',
        icon: <img src={userPlaceHolder} />,
        url: '/dashboards/UserDash',
        children: [
          {
            id: 'userDash',
            title: 'Users',

            messageId: 'User Dashboard',
            type: 'item',
            icon: <FaUserAlt fill='#FF2800' />,
            url: '/dashboards/UserDash',
          },
          {
            id: 'userList',
            title: 'UserList',

            messageId: 'User List',
            type: 'item',
            icon: <FaUserAlt fill='#FF2800' />,
            url: '/dashboards/UserList',
          },
        ],
      },
      {
        id: 'Dewormer',
        title: 'Dewormer',
        // messageId: 'sidebar.app.dashboard.userDash',
        messageId: 'Car Drop Down',
        type: 'collapse',
        icon: <img src={CarMakeIcon} />,
        // url: '/dashboards/Dewormers',
        children: [
          {
            id: 'Vaccine',
            title: 'Vaccine',
            // messageId: 'sidebar.app.dashboard.userDash',
            messageId: 'Car Make',
            type: 'item',
            icon: <img src={CarMakeIcon} />,
            url: '/dashboards/carDropDown',
          },
          {
            id: 'Car Drop Down',
            title: 'Car Drop Down',
            // messageId: 'sidebar.app.dashboard.userDash',
            messageId: 'Car Models',
            type: 'item',
            icon: <img src={CarIcon} />,
            url: '/dashboards/carModel',
          },
        ],
      },
    ],
  },
];
export default routesConfig;
