import React, {useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import {Box} from '@mui/material';

import {AppLoader} from '@crema';
import {baseUrl} from 'config/utils';
import axios from 'axios';
import {userPlaceHolder, imageUL, cross} from 'assets/images';
import {useFilePicker} from 'use-file-picker';
import {FETCH_ERROR, SHOW_MESSAGE} from 'shared/constants/ActionTypes';
import {useDispatch} from 'react-redux';
import {setAuthToken} from './../../../../@crema/services/auth/jwt-auth/index';
const PersonalInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [openFileSelector, {filesContent}] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    limitFilesConfig: {max: 1},
    maxFileSize: 50,
  });
  const dispatch = useDispatch();
  const [uploadImage, setUploadImage] = useState(null);
  const [updateData, setUpdateData] = useState({
    fullName: '',
    email: '',
    contact: '',
  });
  useEffect(() => {
    getUserData();
  }, []);
  useEffect(() => {
    {
      filesContent.length <= 0
        ? setUploadImage(null)
        : setUploadImage(filesContent[0]?.content);
    }
  }, [filesContent]);
  const getUserData = async () => {
    setIsLoading(true);
    const JWT = localStorage.getItem('JWTShowOff');
    try {
      let data = await axios.get(`${baseUrl}admin/getMe`, {
        headers: {
          Authorization: JWT,
        },
      });
      console.log('get me api response', data);

      if (data?.status == 200) {
        setUserData(data?.data?.user);
        setIsLoading(false);
        setTimeout(() => {
          setImageLoading(false);
        }, 10000);
        // setImageLoading(false);
        console.log('get me api response', data);
      }
    } catch (error) {
      console.log(error, 'api payload');
      setIsLoading(false);
    }
  };

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], {type: mimeString});
  }

  const SaveImageChanges = async () => {
    if (uploadImage != null) {
      const JWT = localStorage.getItem('JWTShowOff');
      const file = DataURIToBlob(uploadImage);
      const formData = new FormData();
      formData.append('file', file, 'image.jpg');
      try {
        let data = await axios.post(`${baseUrl}media/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: JWT,
          },
        });
        console.log(
          'upload-api-response------------------',
          data.data.data.insertedIds[0],
        );
        if (data?.status == 200) {
          UpdateProfile(data.data.data.insertedIds[0]);
        }
      } catch (error) {
        console.log(error, 'api payload');
        setIsLoading(false);
      }
    } else {
      UpdateProfile();
    }
  };
  const UpdateProfile = async (imageId) => {
    console.log('UserDataState----------------------------', userData);
    let payload = {};
    if (imageId) {
      if (imageId && updateData.fullName == '' && updateData.contact == '') {
        payload = {
          media: imageId,
          fullName: userData.fullName,
          contact: userData.contact,
        };
        uploadToApi(payload);
      } else if (imageId && updateData.fullName && updateData.contact == '') {
        payload = {
          media: imageId,
          fullName: updateData.fullName,
        };
        uploadToApi(payload);
      } else if (imageId && updateData.fullName == '' && updateData.contact) {
        payload = {
          media: imageId,
          fullName: userData.fullName,
          contact: updateData.contact,
        };
        uploadToApi(payload);
      } else if (imageId && updateData.fullName && updateData.contact) {
        payload = {
          media: imageId,
          fullName: updateData.fullName,
          contact: updateData.contact,
        };
        uploadToApi(payload);
      }
    } else {
      console.log('InsideElseCondition------------------');

      if (updateData.fullName == '' && updateData.contact == '') {
        payload = {
          fullName: userData.fullName,
          contact: userData.contact,
        };
        uploadToApi(payload);
      } else if (updateData.fullName && updateData.contact == '') {
        payload = {
          fullName: updateData.fullName,
          contact: userData.contact,
        };
        uploadToApi(payload);
      } else if (updateData.fullName == '' && updateData.contact) {
        payload = {
          fullName: userData.fullName,
          contact: updateData.contact,
        };
        uploadToApi(payload);
      } else if (updateData.fullName && updateData.contact) {
        payload = {
          fullName: updateData.fullName,
          contact: updateData.contact,
        };
        uploadToApi(payload);
      }
    }
    // if (imageId && updateData.fullName == '' && updateData.contact == '') {
    //   payload = {
    //     fullName: userData.fullName,
    //     media: imageId,
    //   };
    //   uploadToApi(payload);
    // } else if (
    //   imageId == '' &&
    //   updateData.fullName &&
    //   updateData.contact == ''
    // ) {
    //   payload = {
    //     fullName: updateData.fullName,
    //   };
    //   uploadToApi(payload);
    // } else if (
    //   imageId == '' &&
    //   updateData.fullName == '' &&
    //   updateData.contact
    // ) {
    //   payload = {
    //     fullName: userData.fullName,
    //     contact: updateData.contact,
    //   };
    //   uploadToApi(payload);
    // } else if (imageId && updateData.fullName == '' && updateData.contact) {
    //   payload = {
    //     fullName: userData.fullName,
    //     contact: updateData.contact,
    //     media: imageId,
    //   };
    //   uploadToApi(payload);
    // } else if (imageId && updateData.fullName && updateData.contact == '') {
    //   payload = {
    //     fullName: updateData.fullName,
    //     media: imageId,
    //   };
    //   uploadToApi(payload);
    // } else if (updateData.fullName && imageId && updateData.contact) {
    //   payload = {
    //     fullName: userData.fullName,
    //     media: imageId,
    //     contact: updateData.contact,
    //   };
    //   uploadToApi(payload);
    // }
  };
  const uploadToApi = async (payload) => {
    const JWT = localStorage.getItem('JWTShowOff');
    console.log('UpdateUserToken', JWT);
    try {
      let data = await axios.patch(`${baseUrl}admin/updateProfile`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: JWT,
        },
      });
      console.log('Repsonse of update user');
      if (data?.status == 200) {
        setAuthToken(data?.data.session.accessToken);
        getUserData();
        setUploadImage(null);
        setUpdateData({
          fullName: '',
          email: '',
          contact: '',
        });
        dispatch({
          type: SHOW_MESSAGE,
          payload: ` Information Update Successfully.`,
        });
      }
    } catch (error) {
      console.log(error, 'api payload');
      setIsLoading(false);
      dispatch({
        type: FETCH_ERROR,
        payload: 'Something Went Wrong Please Try Again.',
      });
    }
  };
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: '100%',
        height: '50vh',
      }}
    >
      {isLoading == false ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              height: '30%',
              width: '95%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {imageLoading == true ? (
              <div
                style={{position: 'relative', height: '90px', width: '90px'}}
              >
                <AppLoader />
              </div>
            ) : (
              <>
                <img
                  src={
                    uploadImage != null
                      ? filesContent[0]?.content
                      : userData?.media != null
                      ? userData?.media.path
                      : userPlaceHolder
                  }
                  style={{
                    height: '90px',
                    width: '90px',
                    borderRadius: '150px',
                    objectFit: 'cover',
                    animation: 'fadeIn 5s',
                  }}
                />
                {uploadImage != null ? (
                  <span
                    style={{
                      height: '90px',
                      width: '85px',
                      position: 'absolute',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignContent: 'flex-end',
                      alignItems: 'flex-start',
                    }}
                    onClick={() => {
                      setUploadImage(null);
                    }}
                  >
                    <span
                      style={{
                        height: '20px',
                        width: '20px',
                        objectFit: 'cover',
                        cursor: 'pointer',
                        backgroundColor: '#FF2800',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        borderRadius: '100px',
                        border: '2px solid #FFF',
                      }}
                    >
                      <img
                        src={cross}
                        style={{
                          height: '10px',
                          width: '10px',
                          objectFit: 'cover',

                          cursor: 'pointer',
                        }}
                      />
                    </span>
                  </span>
                ) : (
                  <span
                    style={{
                      height: '100px',
                      width: '85px',
                      position: 'absolute',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignContent: 'flex-end',
                      alignItems: 'flex-end',
                    }}
                    onClick={() => {
                      openFileSelector();
                    }}
                  >
                    <img
                      src={imageUL}
                      style={{
                        height: '25px',
                        width: '25px',
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }}
                    />
                  </span>
                )}
              </>
            )}
            &nbsp; &nbsp; &nbsp;
            <span>
              <h2>{userData?.fullName}</h2>
              <h6>{userData?.email}</h6>
            </span>
          </div>
          <div
            style={{
              height: '50%',
              width: '100%',
            }}
          >
            <div
              style={{
                height: '50%',

                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <span
                style={{
                  height: '70%',
                  width: '45%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <h4>Name</h4>
                <input
                  style={{
                    height: '60%',
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #DDDDDD',
                    borderRadius: '6px',
                  }}
                  placeholder={userData?.fullName}
                  onChange={(e) => {
                    setUpdateData({...updateData, fullName: e.target.value});
                  }}
                />
              </span>
              <span
                style={{
                  height: '70%',
                  width: '45%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <h4>Email</h4>
                <input
                  style={{
                    height: '60%',
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #DDDDDD',
                    borderRadius: '6px',
                    cursor: 'not-allowed',
                  }}
                  readOnly
                  // value={drugTitle}
                  placeholder={userData?.email}
                  onChange={(e) => {
                    setUpdateData({...updateData, email: e.target.value});
                  }}
                />
              </span>
            </div>
            <div
              style={{
                height: '50%',

                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <span
                style={{
                  height: '70%',
                  width: '45%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <h4>Contact</h4>
                <input
                  style={{
                    height: '60%',
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #DDDDDD',
                    borderRadius: '6px',
                  }}
                  // value={drugTitle}
                  placeholder={
                    userData?.contact != null ? userData?.contact : 'N/A'
                  }
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      contact: e.target.value,
                    });
                  }}
                />
              </span>
              <span
                style={{
                  height: '70%',
                  width: '45%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              ></span>
            </div>
          </div>
          <div
            style={{
              height: '20%',
              width: '95%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',

              alignItems: 'center',
            }}
          >
            <button
              style={{
                height: '40px',
                width: '28%',
                backgroundColor: '#FF2800',
                color: '#FFF',
                borderRadius: '5px',
                fontWeight: 'bold',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={SaveImageChanges}
            >
              Save Changes
            </button>
          </div>
        </div>
      ) : (
        <AppLoader />
      )}
    </Box>
  );
};

export default PersonalInfo;

PersonalInfo.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.string,
};
