import axios from 'axios';
import {baseUrl} from 'config/utils';
// const token = localStorage.getItem('JWTShowOff');

const jwtAxios = axios.create({
  baseURL: baseUrl, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
    // Origin: 'http://localhost:3000',
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = token;
    localStorage.setItem('JWTShowOff', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('JWTShowOff');
  }
};

export const setAuthUser = (user) => {
  if (user) {
    localStorage.setItem('user', user);
  } else {
    localStorage.removeItem('user');
  }
};

export default jwtAxios;
